<template>
  <app-module-view>
    <template slot="body">
      <section class="m-b-10">
        <div class="row">
          <div class="col-lg-12">
            <div class="text-right m-r-5">
              <template v-if="dataLoaded">
                <app-button-edit
                  route-name="restaurant_edit"
                  :record-id="restaurant.id"
                >
                </app-button-edit>
                <app-button-delete-with-usages
                  @deleteRecord="deleteRestaurant"
                  @load-data="checkUsages"
                  @page-change="setUsagesPageAndGetRecords"
                  :data="usages.data"
                  :total-count="usages.totalCount"
                  :page="usages.page"
                  :showLoader="usages.showLoader"
                  :config="usagesDataTableConfig"
                  :showDeleteButton="isAllowedToDelete"
                ></app-button-delete-with-usages>
              </template>
              <app-button-close route-name="restaurant_list"></app-button-close>
            </div>
          </div>
        </div>
      </section>
      <div v-if="dataLoaded" class="card">
        <div class="card-body">
          <table class="table table-bordered table-hover">
            <tbody>
            <tr>
              <th>{{ $t('restaurant.id') }}</th>
              <td>{{ restaurant.id }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.title') }}</th>
              <td>{{ restaurant.title }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.description') }}</th>
              <td>{{ restaurant.description }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.name') }}</th>
              <td>{{ restaurant.name }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.image') }}</th>
              <td>
                <app-media
                  v-if="restaurantImage"
                  :media="restaurantImage"
                  :width="480"
                  :height="270"
                  show-media-info
                >
                </app-media>
              </td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.yearOfReview') }}</th>
              <td>{{ restaurant.yearOfReview }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.address') }}</th>
              <td>
                <div v-if="this.restaurant.expanded.address">
                  {{ this.restaurant.expanded.address.street }}<br>
                  {{ this.restaurant.expanded.address.expanded.city.title }}<br>
                  {{ this.restaurant.expanded.address.expanded.district.title }}<br>
                  {{ this.restaurant.expanded.address.expanded.country.title }}<br>
                </div>
              </td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.openingHours') }}</th>
              <td>{{ restaurant.openingHours }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.phone') }}</th>
              <td>{{ restaurant.phone }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.website') }}</th>
              <td>{{ restaurant.website }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.chef') }}</th>
              <td>{{ restaurant.chef }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.priceLevel') }}</th>
              <td>{{ restaurant.priceLevel }}</td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.rating') }}</th>
              <td>{{ restaurant.rating }}</td>
            </tr>
            <tr v-if="restaurant.expanded.cuisineCategories">
              <th>{{ $t('restaurant.cuisineCategories') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in restaurant.expanded.cuisineCategories"
                    :key="`item-${index}`"
                  >
                    {{ value.title }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.pros') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in restaurant.prosCons.pros"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-plus text-danger"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            <tr>
              <th>{{ $t('restaurant.cons') }}</th>
              <td>
                <ul class="list-unstyled">
                  <li
                    v-for="(value, index) in restaurant.prosCons.cons"
                    :key="`item-${index}`"
                  >
                    <i class="fas fa-minus text-success"></i>
                    {{ value }}
                  </li>
                </ul>
              </td>
            </tr>
            </tbody>
          </table>
          <app-detail-system-rows :record="restaurant"></app-detail-system-rows>
        </div>
      </div>
      <div v-else>
        <app-preloader></app-preloader>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../../components/ModuleView'
import Preloader from '../../components/preloader/Preloader'
import ButtonClose from '../../components/shared/ButtonClose'
import ButtonDeleteWithUsages from '../../components/shared/ButtonDeleteWithUsages'
import ButtonEdit from '../../components/shared/ButtonEdit'
import DetailSystemRows from '../../components/shared/DetailSystemRows'
import Media from '../../components/shared/Media'
import RestaurantModel from '../../model/RestaurantModel'
import { ROLE_SUPER_ADMIN } from '../../model/ValueObject/UserRole'
import NotifyService from '../../services/NotifyService'

export default {
  name: 'RestaurantView',
  data () {
    return {
      restaurant: RestaurantModel,
      error: null,
      dataLoaded: false,
      usages: {},
      usagesDataTableConfig: {
        fields: {
          'field.title': this.$t('article.list.title'),
          'setting.status': this.$t('article.list.status'),
          orderDate: this.$t('article.list.order_date')
        },
        actions: {
          copyToClipboard: 'documentId',
          detail: 'article_detail'
        }
      }
    }
  },
  components: {
    appModuleView: ModuleView,
    appButtonDeleteWithUsages: ButtonDeleteWithUsages,
    appButtonEdit: ButtonEdit,
    appButtonClose: ButtonClose,
    appDetailSystemRows: DetailSystemRows,
    appMedia: Media,
    appPreloader: Preloader
  },
  computed: {
    currentUser () {
      return this.$store.getters['user/currentUser']
    },
    isAllowedToDelete () {
      if (this.currentUser.roles.includes(ROLE_SUPER_ADMIN)) {
        return true
      }
      return false
    },
    restaurantImage () {
      return this.$store.getters['restaurant/image']
    }
  },
  methods: {
    getRestaurant () {
      this.$store.dispatch('restaurant/fetchOne', this.$route.params.id)
        .then(() => {
          this.dataLoaded = true
          this.restaurant = this.$store.getters['restaurant/detail']
        })
    },
    checkUsages () {
      this.$store.commit('restaurant/setUsagesId', this.restaurant.id)
      this.usages = { page: 1, totalCount: 0, data: [], showLoader: true }
      this.getUsagesList()
    },
    getUsagesList () {
      this.usages.showLoader = true
      this.$store.dispatch('restaurant/fetchUsages')
        .then(() => {
          this.usages.data = this.$store.getters['restaurant/usagesList']
          this.usages.totalCount = this.$store.getters['restaurant/usagesTotalCount']
          this.usages.page = this.$store.getters['restaurant/usagesPage']
          this.usages.showLoader = false
        })
    },
    setUsagesPageAndGetRecords (page) {
      this.$store.commit('restaurant/setUsagesPage', page)
      this.getUsagesList()
    },
    deleteRestaurant () {
      if (this.usages.totalCount > 0) {
        NotifyService.setErrorMessage(this.$t('notify.record_not_deleted'))
        return
      }
      this.$store.dispatch('restaurant/deleteRecord', this.restaurant)
        .then(() => {
          if (this.$store.getters['restaurant/error'] === null) {
            NotifyService.setSuccessMessage(this.$t('notify.record_was_deleted'))
            this.$router.push('/articleTest/restaurant')
          } else {
            NotifyService.setErrorMessage(this.$store.getters['restaurant/error'])
          }
        })
        .catch(error => console.log(error))
    }
  },
  mounted () {
    this.getRestaurant()
  }
}
</script>

<style lang="scss" scoped>
.table-bordered th:first-child {
  width: 200px;
}
</style>
